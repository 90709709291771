* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display:block;
}

body {
  color: white;
  background: black;
  user-select: none;
  overflow: hidden; /*[to unlock scroll:overflow: visible;]*/
}

.background {
  background-color: black;
  width: 100%;
  height: 100%;
}

#debug-0{
    position: absolute;
    background-color:black;
    z-index: 50;
    bottom: 0;
}


#debug-1{
    position: absolute;
    background-color:red;
    z-index: 50;
    bottom: 3.5em;
}

