.cube {
  border: 0px solid white;
  right: 0;
  top: 0;
  outline: none;
  border-radius: 4px;
  background: transparent;
  position: absolute;
  padding: 1em;
  z-index: 1;
  width: 60px;
  height: 60px;
}

.cube img {
  filter: invert(30%);
}
.cube:hover {
  filter: invert(0%);
}

.social-networks {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.invert {
  filter: invert(100%);
}
.social-networks img {
  width: 1.2em;
  margin-right: 4px;
}

img:hover {
  filter: invert(50%);
}


 .error-text {
     text-align: center;
     min-height: 150px;
     margin: 0 auto;
     overflow-wrap: break-word;
     word-wrap: break-word; /* Firefox support */
     font-size: 14px;

 }

 a {
     color: black;
 }

 a:hover {
     filter: invert(50%);
 }

 .or {
    text-transform: uppercase;;
    color: #ff5404 !important;

 }
